document.addEventListener('click', e => {
    if (!e.target.closest)
    {
        return;
    }

    let prev = e.target.closest('.gallery-prev');
    let next = e.target.closest('.gallery-next');

    if (prev || next)
    {
        e.preventDefault();

        let gallery = e.target.closest('.gallery');
        let current = gallery.querySelector('.gallery-item.current');
        let spacer = gallery.querySelector('.gallery-item-spacer');
        let items = gallery.querySelector('.gallery-items');
        let allItems = Array.from(items.querySelectorAll('.gallery-item'));
        let itemCount = (allItems.length - 1) / 2;
        let currentItemIndex = allItems.indexOf(current);
        let nextItemIndex = currentItemIndex + (prev ? -1 : 1);
        let nextWidth = spacer.offsetWidth;

        let nextItem = allItems[nextItemIndex];
        if (nextItem)
        {
            let scrollAmount = nextWidth * (prev ? -1 : 1);
            if (items.scrollLeft + scrollAmount < 0)
            {
                nextItem = allItems[nextItemIndex + itemCount];
                let prevItem = allItems[nextItemIndex + itemCount - 1];
                gallery.classList.add('dont-animate');
                current.classList.remove('current');
                prevItem.classList.add('current');
                items.scrollLeft += nextWidth * itemCount;
                gallery.classList.remove('dont-animate');
                current = prevItem;
            }
            if (items.scrollLeft + scrollAmount > items.scrollWidth - items.offsetWidth)
            {
                nextItem = allItems[nextItemIndex - itemCount];
                let prevItem = allItems[nextItemIndex - itemCount + 1];
                gallery.classList.add('dont-animate');
                current.classList.remove('current');
                prevItem.classList.add('current');
                items.scrollLeft -= nextWidth * itemCount;
                gallery.classList.remove('dont-animate');
                current = prevItem;
            }

            let newScroll = Math.round((items.scrollLeft + scrollAmount) / nextWidth) * nextWidth;
            scrollAmount = newScroll - items.scrollLeft;

            items.scrollBy({
                left: scrollAmount,
                behavior: 'smooth'
            });

            nextItem.classList.add('current');
            current.classList.remove('current');
        }
    }
});

window.addEventListener('resize', () => {
    let itemss = document.querySelectorAll('.gallery-items');
    for (let items of itemss)
    {
        let spacer = items.querySelector('.gallery-item-spacer');
        let nextWidth = spacer.offsetWidth;
        items.scrollLeft = Math.round(items.scrollLeft / nextWidth) * nextWidth;
    }
})
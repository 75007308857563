import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { loadMap } from '@blixem/google-maps-bundle/map.js';

class LocationMap
{
    constructor()
    {
        this.container = null;
        this.map = null;
        this.markers = [];
        this.clusterer = null;

        this.categories = {
            woonbegeleiding: { icon: 'home' },
            dagbesteding: { icon: 'heart' },
            leren: { icon: 'graduation-cap' },
            behandeling: { icon: 'circle-plus' },
            vrijetijd: { icon: 'clock' },
        };
    }

    async init(container)
    {
        this.container = container;
        const { Map } = await google.maps.importLibrary('maps');
        const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary('marker');

        this.map = new google.maps.Map(container, {
            clickableIcons: false,
            zoom: 10,
            center: { lat: 51.4793049, lng: 3.4894065 },
            mapId: '5315082f5e4dc7e8'
        });

        this.clusterer = new MarkerClusterer({
            map: this.map,
            renderer: {
                render: function ({ count, position }, stats) {
                    const pinElement = document.createElement('div');
                    pinElement.classList.add('locations-pin');
                    pinElement.classList.add(`locations-pin-cluster`);
                    pinElement.innerHTML = `<i><b>${count}</b></i>`

                    const marker = new google.maps.marker.AdvancedMarkerElement({
                        position,
                        content: pinElement,
                    });

                    return marker;
                }
            }
        });
    }

    setLocations(locations, fitBounds = true)
    {
        let bounds = new google.maps.LatLngBounds();

        this.clusterer.clearMarkers();
        this.markers.forEach(marker => marker.setMap(null));

        this.markers = locations.map((location, i) => {
            const position = new google.maps.LatLng(location.lat, location.lng);
            const category = this.categories[location.category] || this.categories['woonbegeleiding'];

            const pinElement = document.createElement('div');
            pinElement.classList.add('locations-pin');
            pinElement.classList.add(`locations-pin-${location.category}`);
            pinElement.innerHTML = `<i class="fas fa-${category.icon}"></i>`;

            const contentElement = document.createElement('div');
            contentElement.classList.add('locations-content');
            contentElement.innerHTML = `<a href="${location.url}">${location.title}<i class="fas fa-angle-right"></i></a>`;
            pinElement.appendChild(contentElement);

            const marker = new google.maps.marker.AdvancedMarkerElement({
                position,
                content: pinElement
            });
            marker.pinElement = pinElement;

            marker.addListener('click', () => {
                this.markers.forEach(m => {
                    let open = m === marker ? !m.pinElement.classList.contains('locations-pin-active') : false
                    m.pinElement.classList.toggle('locations-pin-active', open);
                    m.zIndex = open ? (google.maps.Marker.MAX_ZINDEX + 1) : null;
                });
            });

            bounds.extend(position);

            return marker;
        });

        this.clusterer.addMarkers(this.markers);

        if (fitBounds)
        {
            this.map.fitBounds(bounds);
        }
    }
}

document.addEventListener('DOMContentLoaded', () => {
    // @todo key uit template halen
    loadMap('AIzaSyDPuSDFF6r3M1r43jdJStWAlm7q3J18wN0').then(() => {
        let container = document.querySelector('.locations-map');
        if (container)
        {
            let locations = [];
            for (let item of document.querySelectorAll('.locations-item'))
            {
                if (!item.dataset.latitude || !item.dataset.longitude)
                {
                    continue;
                }

                let category = item.querySelector('.locations-item-service-name');

                locations.push({
                    lat: parseFloat(item.dataset.latitude),
                    lng: parseFloat(item.dataset.longitude),
                    title: item.querySelector('.locations-item-name').textContent.trim(),
                    url: item.href,
                    category: category ? category.textContent.split(',').shift().split('(').shift().trim().replace(/\s/g, '').toLowerCase() : 'woonbegeleiding',
                });

            }

            let locationMap = new LocationMap();
            locationMap.init(container).then(
                () => locationMap.setLocations(locations, true)
            );
        }
    });
});
import './_bundles-app.js';

if (localStorage.getItem('contrast') === 'high')
{
    document.body.classList.add('high-contrast');
}

document.addEventListener('DOMContentLoaded', () => {
    for (let viewTracker of document.querySelectorAll('.gors-view'))
    {
        setTimeout(function() {
            fetch('/api/gors-view', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                },
                body: new URLSearchParams({
                    id: viewTracker.dataset.contentEntryId,
                    token: viewTracker.dataset.token
                }),
            })
        }, 5000);
    }
});
document.addEventListener('DOMContentLoaded', e => {
    let homeSlider = document.querySelector('.home-image');
    if (homeSlider)
    {
        function next()
        {
            let current = homeSlider.querySelector('.current');
            let next = current.nextElementSibling || homeSlider.firstElementChild;
            current.classList.remove('current');
            next.classList.add('current');
        }

        setInterval(next, 5000);
    }
});
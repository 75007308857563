import './app.less';
import Routing from 'fos-router';

fetch(Routing.generate('cms_admin_actions') + '?' + new URLSearchParams({
    path: window.location.pathname,
    locale: document.documentElement.lang,
}), {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
    },
}).then(response => response.json()).then(data => {
    if (data.actions && data.actions.length > 0)
    {
        let actions = document.createElement('div');
        actions.classList.add('cms-admin-actions');
        for (let action of data.actions)
        {
            let link = document.createElement('a');
            link.href = action.url;
            link.textContent = action.label;
            actions.appendChild(link);
        }
        document.body.appendChild(actions);
    }
}).catch(() => {});
